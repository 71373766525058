import _ from "lodash"
import { Dish } from "src/entities/dish/Dish"
import { Modifier } from "src/entities/dish/Modifier"
import { ModifierItem } from "src/entities/dish/ModifierItems"
import Receipt from "src/entities/dish/Receipt"

const data: Dish = {
    id: "",
    dishName: null,
    categoryId: null,
    stationId: null,
    price: null,
    cost: null,
    taxId: null,
    imagePreview: null,
    color: null,
    soldByWeight: false,
    barcode: null,
    howToPrepare: null,
    cookingTimeMin: null,
    cookingTimeSec: null,
    isActive: true,
    modifiers: [],
    receipt: [],
    imageData: null,
    contentType: null,
    showOnDigitalMenu: false
}
const dishReducer = (state: Dish = data, action) => {
    switch (action.type) {
        case 'SET_DISH':
            return action.payload

        case 'ADD_DISH_RECEIPT':
            {
                const dish: Dish = state
                const receipt: Receipt = action.payload

                dish.receipt = [...dish.receipt, receipt]
                return { ...dish }
            }

        case "SET_DISH_PRICE":
            {
                const dish: Dish = state
                const price: number = action.payload


                return { ...dish, price }
            }

        case "SET_DISH_COST":
            {
                const dish: Dish = state
                const cost: number = action.payload

                return { ...dish, cost }
            }

        case "UPDATE_DISH_RECEIPT_ITEM":
            {
                const dish: Dish = state
                const receipt: Receipt = action.payload

                return { ...dish, receipt: [...dish.receipt.filter(x => x.id !== receipt.id), receipt] }
            }

        case "ADD_DISH_RECEIPT_ITEM":
            {
                const dish: Dish = state
                const receipt: Receipt = action.payload

                return { ...dish, receipt: [...dish.receipt, receipt] }
            }

        case "REMOVE_DISH_RECEIPT_ITEM":
            {
                const dish: Dish = state
                const receipt: Receipt = action.payload

                dish.receipt = [...dish.receipt.filter(x => x.id !== receipt.id)]
                return { ...dish }
            }

        case "DISH_MODIFIER_SAVE":
            {
                const dish: Dish = state
                const modifier: Modifier = action.payload.modifier
                const isPredefined: boolean = action.payload.isPredefined

                // if (!isPredefined) dish.modifiers = [...dish.modifiers, modifier]
                // else 

                dish.modifiers = [...dish.modifiers.filter(x => x.id !== modifier.id), modifier]

                return { ...dish }
            }

        case "DISH_MODIFIER_REMOVE":
            {
                const dish: Dish = state
                const modifier: Modifier = action.payload

                // if (!isPredefined) dish.modifiers = [...dish.modifiers, modifier]
                // else 

                dish.modifiers = [...dish.modifiers.filter(x => x.id !== modifier.id)]

                return { ...dish }
            }

        case "DISH_MODIFIER_ITEM_SET":
            {
                const dish: Dish = state
                const modifierItem: ModifierItem = action.payload

                const modifierFound: Modifier | undefined = dish.modifiers.find(x => x.id === modifierItem.modifierId)
                if (modifierFound) {
                    modifierFound.modifierItems = [...modifierFound.modifierItems.filter(x => x.id !== modifierItem.id), modifierItem]
                    dish.modifiers = [...dish.modifiers.filter(x => x.id !== modifierFound.id), modifierFound]
                }

                return JSON.parse(JSON.stringify(dish))
            }

        case "DISH_MODIFIER_ITEM_REMOVE":
            {
                const dish: Dish = state
                const modifierItem: ModifierItem = action.payload

                const modifierFound: Modifier | undefined = dish.modifiers.find(x => x.id === modifierItem.modifierId)
                if (modifierFound) {
                    modifierFound.modifierItems = [...modifierFound.modifierItems.filter(x => x.id !== modifierItem.id)]
                    dish.modifiers = [...dish.modifiers.filter(x => x.id !== modifierFound.id), modifierFound]
                }

                return JSON.parse(JSON.stringify(dish))
            }

        default:
            return state
    }
}

export default dishReducer